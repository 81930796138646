import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Search from '../components/Search'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    search: {
      maxWidth: theme.spacing(80),
      padding: theme.spacing(20, 2, 2),
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(15, 2, 2),
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(10, 2, 2),
      },
    },
    searchMenu: {
      left: `${theme.spacing(-5.7)}px !important`,
      maxWidth: `${theme.spacing(80)}px !important`,
    },
    subtitle: {
      padding: theme.spacing(2),
      zIndex: 2,

      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h5,
      },
    },
    title: {
      padding: theme.spacing(2),
      zIndex: 2,

      [theme.breakpoints.down('sm')]: {
        ...theme.typography.h3,
      },

      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h4,
      },
    },
    img: {
      top: 0,
      width: '100%',
      position: 'absolute'
    }
  })
)

export const query = graphql`
  query {
    file {
      publicURL
      name
    }
  }
`

const IndexPage = ({ data }) => {
  const classes = useStyles()

  const header = (
    <div className={classes.container}>
      <Seo title="Главная" />
      {/* {data.file.publicURL && <img className={classes.img} src={data.file.publicURL} />} */}
      <Typography className={classes.title} align="center" variant="h2">
        Справочный центр
      </Typography>
      <Typography className={classes.subtitle} align="center" variant="h4">
        "Открытый Город"
      </Typography>
      <Search
        className={classes.search}
        label=""
        variant="outlined"
        shrink={true}
        placeholder={'Введите ключевое слово, например, "Создание заявки"'}
        innerClasses={{ menu: classes.searchMenu }}
      />
    </div>
  )

  return (
    <Layout header={header}>
    </Layout>
  )
}

export default IndexPage
